import request from "../utils/request";

export default {
  // 检查是否存在
  sendCode(type,phone) {
    return request({
      url: "/sms/"+type+"/" + phone,
      method: "get",
    });
  },
  register(data) {
    return request({
      url: "/register",
      method: "post",
      data: data,
    });
  },
  login(data) {
    return request({
      url: "/login",
      method: "post",
      data: data,
    });
  },
};
