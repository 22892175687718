<template>
  <div id="container">
    <div class="top">
      <div class="header">
        <img
          src="https://www-pigwan-net.oss-cn-beijing.aliyuncs.com/amazon/images/pigwan_logo@100x100.png" class="logo"
          alt="logo">
        <span class="title">Pigwan 便携书签</span>
      </div>
      <div class="desc">简单快捷，互帮互助，提高工作效率！</div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="links">
        <a href="#">帮助</a>
        <a href="#">隐私</a>
        <a href="#">条款</a>
      </div>
      <div class="copyright">
        Copyright © 2022
        <a class="p-link" target="_blank" href="https://pigwan.me">🎉<span style="padding-left: 10px">version 1.0</span></a>
        <a class="p-link" target="_blank" href="https://pigwan.me">🛠️<span style="padding-left: 10px">Powered by Pigwan</span></a>
        <a class="p-link" target="_blank" href="https://www.miit.gov.cn/">👮<span style="padding-left: 10px">豫ICP备16017200号-3</span></a>
      </div>
    </div>

  </div>

</template>

<script>
import store from "../../store";

export default {
  name: "LoginLayout",
  components: {},
  created() {
    store.commit("login", "");
    store.commit("changeAuthorization", "");
  }
};
</script>

<style scoped>
#container {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 110px 0 144px;
  background-image: url('https://www-pigwan-net.oss-cn-beijing.aliyuncs.com/amazon/images/background.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  text-align: center;
}

.top {

}

.header {
  height: 44px;
  line-height: 44px;
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
  border-style: none;
}

.title {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, .85);
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.desc {
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}

.main{
  width: 368px;
  min-width: 260px;
  margin: 0 auto;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 48px 0 24px;
  padding: 0 16px;
  text-align: center;
}

.links{
  margin-bottom: 8px;
  font-size: 14px;
}
.links a:not(:last-child){
  margin-right: 40px;
}
.links a{
  color: rgba(0,0,0,.45);
  transition: all .3s;
  text-decoration: none;
}
.copyright{
  color: rgba(0,0,0,.45);
  font-size: 14px;
}
</style>