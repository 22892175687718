export default {
  //file转base64
  getBase64(img, callback) {
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function (e) {
      callback(e.target.result);
    };
  },
  //判断是否为手机号
  isPhoneNumber(tel) {
    return /^0?1[3|4|5|6|7|8][0-9]\d{8}$/.test(tel);
  },
  //判断是否为字母数字下划线
  isValid(str) {
    return /^\w+$/.test(str);
  },
  //判断是否为字母
  isLetter(str) {
    for (let i in str) {
      let asc = str.charCodeAt(i);
      if ((asc >= 65 && asc <= 90) || (asc >= 97 && asc <= 122)) {
        return true;
      }
    }
    return false;
  },
  //判断是否为json
  isJsonString(str) {
    try {
      if (typeof JSON.parse(str) == "object") {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  },
  //判断链接图片是否有效
  checkImgExists(imgurl) {
    return new Promise(function (resolve, reject) {
      var ImgObj = new Image();
      ImgObj.src = imgurl;
      ImgObj.onload = function (res) {
        resolve(res);
      };
      ImgObj.onerror = function (err) {
        reject(err);
      };
    });
  },
};
