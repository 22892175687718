<template>
  <div style="max-width: 1000px; margin: 0 auto; padding: 30px;overflow: auto;height: 100vh">

    <div style="margin: 50px;padding: 50px;background-color: #FFFFFF">
      <div class="btn">
        <a-button type="primary" href="javascript:((function(s, d, e) {var u=location;var f = 'https://link.pigwan.net/add?url='+e(u.href)+'&title='+e(d.title)+'&uid=&_t='+new Date().getTime();function a() {if (!window.open(f, 'bdshare', 'toolbar=0,status=0,resizable=1,width=700,height=450,left='+(s.width - 700) / 2+',top='+(s.height - 650) / 2)) u.href = f;};if (/Firefox/.test(navigator.userAgent)) setTimeout(a, 0);else a();})(screen, document, encodeURIComponent));">
          添加至收藏夹
        </a-button>
      </div>

      <div class="step">
        <div>第一步，拖动上边的按钮到收藏夹。</div>
        <img src="https://oss.pigwan.net/link/images/1.jpg" alt="">
      </div>

      <div class="step">
        <div>第二步，在你要收藏的页面，点击这个收藏夹</div>
        <img src="https://oss.pigwan.net/link/images/2.jpg" alt="">
      </div>

      <div class="step">
        <div>第三步，点击添加，收藏成功</div>
        <img src="https://oss.pigwan.net/link/images/3.jpg" alt="">
      </div>

      <div class="step">
        <div>查看收藏链接===>>><a href="https://link.pigwan.net">https://link.pigwan.net</a></div>
        <img src="https://oss.pigwan.net/link/images/4.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from "ant-design-vue/es";

export default {
name: "Help",
  components:{
    AButton:Button,
  }
}
</script>

<style scoped>
.step{
  margin: 30px 0;
  font-size: 24px;
}
.step img{
  width: 100%;
  margin-top: 10px;
}
</style>