<template>
  <div
    class="content"
    style="
      height: calc(100vh - 134px);
      background-color: #f8f8f8;
      padding: 30px;
      overflow: auto;
    "
  >
    <div class="top">
      <a-row :gutter="40">
        <a-col :span="24 / ads.length" v-for="ad in ads" :key="ad.key">
          <div
              @click="alert('暂未开放');"
            class="top-item"
            v-bind:style="{ backgroundColor: ad.backgroundColor }"
          >
            <a :href="ad.link">
              <icon-font
                style="
                  font-size: 20px;
                  vertical-align: middle;
                  padding-right: 5px;
                "
                :type="ad.icon"
              />
              <span>{{ ad.title }}</span>
            </a>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="sousuo">
      <div class="search">
        <div class="search-box">
          <span
            class="search-icon"
            style="
              background: url('https://oss.pigwan.net/link/images/search_icon.png')
                0px -280px;
              opacity: 1;
              z-index: 9;
            "
          ></span>
          <a-input
            v-model:value="searchKeyword"
            v-on:keyup.enter="search()"
            type="text"
            class="search-input"
            autocomplete="off"
            placeholder="请输入关键字，按回车 / Enter 搜索"
          />
          <button
            class="search-btn"
            style="cursor: pointer"
            id="search-btn"
            @click="search()"
          >
            <icon-font type="link-search" />
          </button>
        </div>
        <!-- 搜索热词 -->
        <div class="box search-hot-text" id="box" style="display: none">
          <ul></ul>
        </div>
        <!-- 搜索引擎 -->
        <div class="search-engine" style="display: none">
          <div class="search-engine-head">
            <strong class="search-engine-tit">选择您的默认搜索引擎：</strong>
            <div class="search-engine-tool">
              搜索热词： <span id="hot-btn"></span>
            </div>
          </div>
          <ul class="search-engine-list search-engine-list_zmki_ul">
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -25px;
                "
              ></span
              >必应
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -50px;
                "
              ></span
              >搜狗
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px -25px;
                "
              ></span
              >好搜
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px
                    0px;
                "
              ></span
              >百度
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px
                    0px;
                "
              ></span
              >谷歌
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px -50px;
                "
              ></span
              >淘宝
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -75px;
                "
              ></span
              >京东
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px -75px;
                "
              ></span
              >天猫
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -100px;
                "
              ></span
              >1688
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px -100px;
                "
              ></span
              >知乎
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -125px;
                "
              ></span
              >微博
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px -125px;
                "
              ></span
              >B站
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -150px;
                "
              ></span
              >豆瓣
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -105px -150px;
                "
              ></span
              >优酷
            </li>
            <li>
              <span
                style="
                  background: url('https://oss.pigwan.net/link/images/search_icon.png') -80px -175px;
                "
              ></span
              >GitHub
            </li>
          </ul>
        </div>
        <!--        <div class="bt-link">-->
        <!--          <a href="https://www.k1v.cn/6528.html" target="_blank"-->
        <!--            >福利：【WPS全家桶】WPS政府企业版_合集多版本-->
        <!--            无广告带VBA组件。免费下载！</a-->
        <!--          >-->
        <!--        </div>-->
      </div>
    </div>

    <div class="items">
      <a-empty v-if="linkCount === 0" :image="simpleImage">
        <template #description>
          <span style="color: #c5c5c5"> 暂无数据 </span>
        </template>
        <a-button
            v-if="isLogin"
          style="margin-top: 20px; width: 160px"
          type="primary"
          @click="addLink()"
          >添加网址
        </a-button>
      </a-empty>

      <div v-else>
        <template v-for="product in products" :key="product.id">
          <template v-if="product.children && product.children.length > 0">
            <h4 class="text-gray" :id="product.title">
              <icon-font type="link-tags" style="margin-right: 7px" />
              {{ product.title }}
            </h4>
            <a-row :gutter="30">
              <a-col
                :span="6"
                v-for="(item, index) in product.children"
                :key="index"
              >
                <div
                  class="product-item"
                  :class="{ over: index + item.id == overIndex }"
                  @mouseenter="enter(index + item.id)"
                  @mouseleave="leave()"
                  @click="openLink(item)"
                >
                  <div class="xe-comment-entry">
                    <a v-if="item.icon.length === 1" class="xe-user-img">
                      <div
                        class="text-icon"
                        :style="{
                          backgroundColor:
                            iconBgColor[
                              Math.floor(Math.random() * iconBgColor.length)
                            ],
                        }"
                      >
                        {{ item.icon }}
                      </div>
                    </a>
                    <a v-else class="xe-user-img">
                      <img :src="item.icon" class="img-circle" width="32" />
                    </a>
                    <div class="xe-comment">
                      <a href="#" class="xe-user-name overflowClip_1">
                        <strong>{{ item.title }}</strong>
                      </a>
                      <p class="overflowClip_2">{{ item.description }}</p>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
        </template>
      </div>
    </div>
    <a-modal v-model:visible="addLinkVisible" title="添加链接" width="960px">
      <template #footer>
        <a-button key="submit" type="primary" @click="submitLink()"
          >提交
        </a-button>
      </template>
      <add-link></add-link>
    </a-modal>
  </div>
</template>

<script>
import IconFont from "../../utils/IconFont";
import AddLink from "../../components/AddLink";
import {
  Row,
  Col,
  Empty,
  Button,
  Modal,
  Input,
  message,
} from "ant-design-vue/es";
import eventBus from "vue3-eventbus";
import store from "../../store";
import cate from "../../api/cate";
import link from "../../api/Link";

export default {
  name: "Index",
  components: {
    AddLink,
    IconFont,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AModal: Modal,
    AEmpty: Empty,
    AButton: Button,
  },
  data() {
    return {
      overIndex: -1,
      isLogin: false,
      userInfo: {},
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      addLinkVisible: false,
      searchKeyword: "",
      linkCount: 0,
      iconBgColor: ["#698DE7", "#698DE7", "#F1B569", "#71BC74"],
      ads: [
        {
          id: 1,
          icon: "link-user",
          title: "会员入群",
          link: "https://www.pigwan.me",
          backgroundColor: "#608cee",
        },
        {
          id: 2,
          icon: "link-cloud-download",
          title: "网盘下载",
          link: "https://www.pigwan.me",
          backgroundColor: "#fb5962",
        },
        {
          id: 3,
          icon: "link-book",
          title: "文案书店",
          link: "https://www.pigwan.me",
          backgroundColor: "#fbb359",
        },
        {
          id: 4,
          icon: "link-send",
          title: "建议留言",
          link: "https://www.pigwan.me",
          backgroundColor: "#53bf6b",
        },
      ],
      products: [],
    };
  },
  created() {
    if (store.state.userInfo) {
      this.isLogin = true;
      this.userInfo = store.state.userInfo;
    }
  },
  mounted() {
    if (this.isLogin) {
      this.getLink();
    } else {
      this.getLinkPublic();
    }
    eventBus.on("addLink", (val) => {
      this.addData = val;
    });
  },
  methods: {
    enter(index) {
      this.overIndex = index;
    },
    leave() {
      this.overIndex = -1;
    },

    getLinkPublic() {
      const that = this;
      link
        .listPublic()
        .then((res) => {
          if (res.code === 0) {
            that.products = res.data;
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].children && res.data[i].children.length > 0) {
                that.linkCount += res.data[i].children.length;
              }
            }
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },
    getLink() {
      const that = this;
      link
        .list()
        .then((res) => {
          if (res.code === 0) {
            that.products = res.data;
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].children && res.data[i].children.length > 0) {
                that.linkCount += res.data[i].children.length;
              }
            }
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },
    addLink() {
      this.getCateListAdd();
      this.addLinkVisible = true;
    },
    getCateListAdd() {
      cate
        .list()
        .then((res) => {
          if (res.code === 0) {
            let linkAddCateOptions = [];
            if (res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].children && res.data[i].children.length > 0) {
                  let son = [];
                  for (let j = 0; j < res.data[i].children.length; j++) {
                    son.push({
                      value: res.data[i].children[j].id,
                      label: res.data[i].children[j].title,
                    });
                  }
                  let item = { label: res.data[i].title, options: son };

                  linkAddCateOptions.push(item);
                } else {
                  let item = {
                    value: res.data[i].id,
                    label: res.data[i].title,
                  };
                  linkAddCateOptions.push(item);
                }
              }
            }
            eventBus.emit("linkAddCateOptions", linkAddCateOptions);
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },
    submitLink() {
      const that = this;
      if (that.addData.title === "" || that.addData.title.length > 30) {
        message.warning("网站标题长度在1-30个字符之间");
        return false;
      }

      if (that.addData.url === "" || that.addData.url.length > 250) {
        message.error("网址字符长度在1-250个字符之间");
        return false;
      } else if (that.addData.url.indexOf(".") < 0) {
        message.error("请输入正确的网址");
        return false;
      }

      if (that.addData.cate_id === "" || that.addData.cate_id === 0) {
        message.error("请选择分组");
        return false;
      }
      if (
        that.addData.url.indexOf("http://") < 0 &&
        that.addData.url.indexOf("https://") < 0
      ) {
        that.addData.url = "http://" + that.addData.url;
      }

      if (that.addData.icon.indexOf("http") < 0) {
        that.addData.icon = that.addData.title.substring(0, 1);
      }

      link
        .addData(that.addData)
        .then((res) => {
          if (res.code === 0) {
            message.success(res.msg);
            this.addLinkVisible = false;
            eventBus.emit("addLink", "OK");
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员！");
        });
    },
    search() {
      if (this.isLogin) {
        link
          .addRecord({ keyword: this.searchKeyword })
          .then(() => {
            window.open("https://www.baidu.com/s?wd=" + this.searchKeyword);
          })
          .catch(() => {
            window.open("https://www.baidu.com/s?wd=" + this.searchKeyword);
          });
      } else {
        link
          .publicAddRecord({ keyword: this.searchKeyword })
          .then(() => {
            window.open("https://www.baidu.com/s?wd=" + this.searchKeyword);
          })
          .catch(() => {
            window.open("https://www.baidu.com/s?wd=" + this.searchKeyword);
          });
      }
    },
    openLink(item) {
      if (this.isLogin) {
        link
          .viewTime({ id: item.id })
          .then(() => {
            window.open(item.url);
          })
          .catch(() => {
            window.open(item.url);
          });
      } else {
        window.open(item.url);
      }
    },
  },
};
</script>

<style scoped>
.over {
  top: -10px;
}
.content {
  overflow-y: auto;
  background-color: #f8f8f8;
  height: 200px;
  padding: 10px;
}

.content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
  scrollbar-arrow-color: rgba(0, 0, 0, 0.2);
}

.content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ee3f4d;
  scrollbar-arrow-color: rgba(0, 0, 0, 0.2);
}

.content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.2);
}

.top {
  margin-top: 20px;
}

.top-item {
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all 0.3s ease;
  padding: 18px 10px;
  font-size: 13px;
}

.top-item a {
  color: #ffffff;
}

.sousuo {
  padding: 55px 0 35px 0;
  max-width: 717px;
  margin: 0 auto;
}

.search {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.search-box {
  position: relative;
}

.search-hot-text {
  position: absolute;
  z-index: 100;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background: #fff;
  box-shadow: 0px 4px 5px 0px #cccccc94;
  overflow: hidden;
}

.search-engine {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 15px 0 0 15px;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px #d8d7d7;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  display: none;
  z-index: 999;
}

.search-engine-head {
  overflow: hidden;
  margin-bottom: 10px;
  padding-right: 15px;
}

.search-engine-tit {
  float: left;
  margin: 0;
  font-size: 14px;
  color: #999;
}

.search-engine-tool {
  float: right;
  font-size: 12px;
  color: #999;
}

.search-engine-tool > span {
  float: right;
  display: inline-block;
  width: 25px;
  height: 15px;
  background: url(https://zan.useidea.com/usr/themes/WebStack/images/off_on.png)
    no-repeat 0px 0px;
  cursor: pointer;
}

.search-engine-list:after {
  content: "";
  width: 90px;
  height: 20px;
  position: absolute;
  top: -20px;
  left: 1px;
}

.search-engine ul::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  top: -15px;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
}

.search-engine-list li {
  list-style: none;
  float: left;
  width: 30%;
  line-height: 30px;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 10px 0;
  background: #f9f9f9;
  color: #999;
  cursor: pointer;
}

.search-engine-list li span {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  float: left;
  margin-right: 5px;
  margin-top: 2.5px;
}

.search-icon {
  position: absolute;
  left: 3px;
  top: 5px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 25px;
  cursor: pointer;
  opacity: 0;
}

.search-input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #999;
  outline: none;
  padding-left: 45px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  letter-spacing: 1px;
  color: #ccc;
}

input::-webkit-input-placeholder {
  text-overflow: inherit;
  line-height: initial;
  white-space: pre;
  overflow-wrap: normal;
  -webkit-user-modify: read-only !important;
  overflow: hidden;
}

.search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  line-height: 48px;
  background: transparent;
  border: none;
  font-size: 25px;
  color: #ddd;
  font-weight: bold;
  outline: none;
  padding: 0 10px;
}

.bt-link > a {
  display: inline-block;
  font-size: 12px;
  color: #e8a788;
  padding: 7px 0 0 15px;
}

a {
  outline: 0 !important;
  color: #373e4a;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.text-gray {
  padding-top: 60px;
  color: #555;
  font-weight: 400;
  font-size: 17px;
  margin: 5px 0;
  font-family: inherit;
  line-height: 1.1;
  display: block;
}

.product-item {
  position: relative;
  background: #fff;
  padding: 15px;
  height: 86px;
  cursor: pointer;
  border-radius: 8px;
  margin: 20px 0 0 0;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -moz-box-shadow: 2px 2px 5px #333333;
  -webkit-box-shadow: 2px 2px 5px #333333;
  box-shadow: 2px 2px 5px #3333330d;
  border: 1px solid #fff;
  word-break: break-word;
  border-collapse: collapse;
  border-spacing: 0;
}

.xe-comment-entry img {
  float: left;
  display: block;
  margin-right: 10px;
  border-radius: 50px;
}

.xe-comment-entry .text-icon {
  float: left;
  display: block;
  margin-right: 10px;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  color: #ffffff;
}

.overflowClip_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 13px;
}

.overflowClip_1 strong {
  font-weight: 700;
}

.xe-comment p {
  margin-bottom: 0px;
}

.overflowClip_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #979898;
  margin: 0 0 9px;
  font-size: 13px;
}
</style>
