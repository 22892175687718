<template>
  <div style="width: 1000px;margin: 0 auto;padding: 50px 0">
    <div class="logo">
      <img src="https://oss.pigwan.net/link/images/logo_dark.png" alt="">
    </div>
    <add-link style="background-color: #FFFFFF;padding: 50px 50px 50px 0"></add-link>
    <div style="width: 1000px;height: 60px;background-color: #FFFFFF;text-align: center">
      <a-button style="width: 200px" size="large" type="primary" @click="submitLink()">提交</a-button>
    </div>
  </div>
</template>

<script>
import AddLink from "../../components/AddLink";
import store from "../../store";
import eventBus from "vue3-eventbus";
import router from "../../router";
import cate from "../../api/cate";
import {message,Button} from "ant-design-vue/es";
import link from "../../api/Link";
export default {
  name: "Add",
  data(){
    return{
      isLogin:false,
      userInfo:{},
      addData:{}
    }
  },
  components: {
    AddLink,
    AButton:Button,
  },
  created() {
    if (store.state.userInfo) {
      this.isLogin = true;
      this.userInfo = store.state.userInfo;
    }
  },
  mounted() {
    this.checkLogin()
    eventBus.on("addLink", (val) => {
      this.addData = val;
    });
    this.getCateListAdd();

    let data = {
      'title':this.$route.query.title,
      'url':this.$route.query.url,
    }
    eventBus.emit("pageQueryData", data);
  },
  methods:{
    checkLogin(){
      if (this.isLogin === false){
        router.push('/login');
      }
    },
    getCateListAdd() {
      cate
          .list()
          .then((res) => {
            if (res.code === 0) {
              let linkAddCateOptions = [];
              if (res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                  if (res.data[i].children && res.data[i].children.length > 0) {
                    let son = [];
                    for (let j = 0; j < res.data[i].children.length; j++) {
                      son.push({
                        value: res.data[i].children[j].id,
                        label: res.data[i].children[j].title,
                      });
                    }
                    let item = {label: res.data[i].title, options: son};

                    linkAddCateOptions.push(item);
                  } else {
                    let item = {
                      value: res.data[i].id,
                      label: res.data[i].title,
                    };
                    linkAddCateOptions.push(item);
                  }
                }
              }
              eventBus.emit("linkAddCateOptions", linkAddCateOptions);
            } else {
              message.error(
                  "出现错误，请联系管理员！错误代码：" +
                  res.code +
                  ",错误信息：" +
                  res.msg
              );
            }
          })
          .catch((error) => {
            console.log(error);
            message.error("出现错误，请联系管理员");
          });
    },
    submitLink(){
      const that = this;
      if (that.addData.title === "" || that.addData.title.length > 30) {
        message.warning("网站标题长度在1-30个字符之间");
        return false;
      }

      if (that.addData.url === "" || that.addData.url.length > 250) {
        message.error("网址字符长度在1-250个字符之间");
        return false;
      } else if (that.addData.url.indexOf(".") < 0) {
        message.error("请输入正确的网址");
        return false;
      }

      if (that.addData.cate_id === "" || that.addData.cate_id === 0) {
        message.error("请选择分组");
        return false;
      }
      if (
          that.addData.url.indexOf("http://") < 0 &&
          that.addData.url.indexOf("https://") < 0
      ) {
        that.addData.url = "http://" + that.addData.url;
      }

      if (that.addData.icon.indexOf("http") < 0) {
        that.addData.icon = that.addData.title.substring(0, 1);
      }

      link
          .addData(that.addData)
          .then((res) => {
            if (res.code === 0) {
              message.success(res.msg);
              setTimeout(function (){
                router.push('/')
              },1000);
            } else {
              message.error(
                  "出现错误，请联系管理员！错误代码：" +
                  res.code +
                  ",错误信息：" +
                  res.msg
              );
            }
          })
          .catch((error) => {
            console.log(error);
            message.error("出现错误，请联系管理员！");
          });
    }
  }
}
</script>

<style scoped>

.logo{
  width: 360px;
  height: 80px;
  margin: 50px auto;
}
.logo img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>