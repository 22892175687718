import { createRouter, createWebHistory } from "vue-router";
import BaseLayout from "../layouts/BaseLayout";
import Index from "../views/index/Index";
import Exception from "../views/exception/Exception";
import Msg from "../views/exception/Msg";
import LoginLayout from "../views/login/LoginLayout";
import Login from "../views/login/Login";
import Register from "../views/login/Register";
import Add from "../views/index/Add";
import Help from "../views/index/Help";

const routes = [
  {
    path: "/",
    component: BaseLayout,
    redirect: "/index",
    children: [
      {
        path: "/index",
        component: Index,
        meta: {
          title: "首页",
          requireAuth: false,
        },
      },
    ],
  },
  {
    path: "/add",
    component: Add,
    meta: {
      title: "添加导航",
      requireAuth: true,
    },
  },
  {
    path: "/help",
    component: Help,
    meta: {
      title: "使用说明",
      requireAuth: true,
    },
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [
      {
        path: "/login",
        component: Login,
        meta: {
          title: "登陆",
          requireAuth: false,
        },
      },
      {
        path: "/register",
        component: Register,
        meta: {
          title: "注册",
          requireAuth: false,
        },
      },
    ],
  },
  {
    path: "/exception",
    redirect: "/exception/msg",
    component: Exception,
    children: [
      {
        path: "/exception/msg",
        component: Msg,
        meta: {
          title: "异常信息",
          requireAuth: false,
        },
      },
    ],
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach(() => {
  document.title = "PIGWAN导航";

});

export default router;
