import request from "../utils/request";

export default {
    // 检查是否存在
    list() {
        return request({
            url: "/cate/list",
            method: "get",
        });
    },
    listPublic() {
        return request({
            url: "/public/cate/list",
            method: "get",
        });
    },
    listAdd() {
        return request({
            url: "/cate/list/add",
            method: "get",
        });
    },
    addData(data) {
        return request({
            url: "/cate/add",
            method: "post",
            data: data
        });
    }
};
