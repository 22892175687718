<template>
  <div style="text-align: center;position: absolute;width: 100vw">
      <img style="width: 50vw;height: 50vw;margin: 30px auto;display: block;" src="https://www-pigwan-net.oss-cn-beijing.aliyuncs.com/amazon/images/pigwan_wechat_qrcode.jpeg" alt="">
      您好，有问题请联系管理员！<br>
      微信/手机：<a href="tel:15994061054">15994061054</a><br>
  </div>
</template>

<script>
export default {
  name: "Msg"
};
</script>

<style scoped>

</style>