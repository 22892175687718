<template>
  <router-view />
</template>
<script>
import router from "./router";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    // this.checkWidth();
  },

  methods: {
    checkWidth() {
      if (window.innerWidth < 1336) {
        alert("暂不支持移动端或宽度小于1336px的分辨率,有问题请联系管理员！");
        router.push("/exception");
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  min-width: 1336px;
  background: #f0f2f5;
}
</style>
