import request from "../utils/request";

export default {
    listPublic() {
        return request({
            url: "/public/link/list",
            method: "get",
        });
    },
    list() {
        return request({
            url: "/link/list",
            method: "get",
        });
    },
    addData(data) {
        return request({
            url: "/link/add",
            method: "post",
            data: data
        });
    },
    addRecord(data){
        return request({
            url: "/link/record",
            method: "post",
            data: data
        })
    },
    viewTime(data){
        return request({
            url: "/link/view",
            method: "post",
            data: data
        })
    },
    publicAddRecord(data){
        return request({
            url: "/public/link/record",
            method: "post",
            data: data
        })
    }
};
