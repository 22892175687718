<template>
  <a-form
    ref="formRef"
    :model="formState"
    :label-col="{ style: { width: '200px' } }"
  >
    <a-form-item has-feedback label="标题" name="keywords">
      <a-input
        v-model:value="formState.title"
        type="text"
        autocomplete="off"
        style="width: 700px"
        placeholder="请输入网站标题，如：Pigwan导航"
      />
    </a-form-item>

    <a-form-item has-feedback label="链接" name="keywords">
      <a-input
        v-model:value="formState.url"
        type="text"
        autocomplete="off"
        style="width: 700px"
        placeholder="请输入网站链接，如：https://link.pigwan.net"
        @blur="checkHttp()"
      />
    </a-form-item>

    <a-form-item has-feedback label="分组" name="cate">
      <a-select
        v-model:value="formState.cateTitle"
        style="width: 400px"
        :options="options"
        @change="handleChange"
      ></a-select>
    </a-form-item>

    <a-form-item has-feedback label="图标" name="icon">
      <div
        v-if="formState.icon"
        style="
          width: 50px;
          height: 50px;
          border-radius: 4px;
          border: 1px dashed #ababab;
        "
      >
        <img
          :src="formState.icon"
          style="width: 100%; height: 100%; object-fit: cover"
        />
      </div>
      <div
        v-else
        style="
          width: 50px;
          height: 50px;
          border-radius: 5px;
          background-color: #1890ff;
          color: #ffffff;
          font-size: 36px;
          line-height: 50px;
          text-align: center;
        "
      >
        {{
          formState.title === undefined || formState.title === ""
            ? "P"
            : formState.title.substring(0, 1)
        }}
      </div>
    </a-form-item>

    <a-form-item has-feedback label="描述" name="description">
      <a-textarea
        v-model:value="formState.description"
        placeholder="请输入网站描述，如：这是一个导航网站，可以添加自己的收藏，是一个随身携带的网址收藏夹"
        :auto-size="{ minRows: 2, maxRows: 5 }"
        style="width: 700px"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import {
  message,
  Form,
  FormItem,
  Input,
  Select,
  Textarea,
} from "ant-design-vue/es";
import tool from "../utils/tool";
import eventBus from "vue3-eventbus";
export default {
  name: "AddLink",
  components: {
    AForm: Form,
    AInput: Input,
    ASelect: Select,
    ATextarea: Textarea,
    AFormItem: FormItem,
  },
  data() {
    return {
      formState: {
        title: "",
        cateTitle: "",
        cate_id: "",
        url: "",
        icon: "",
        description: "",
      },
      options: [],
    };
  },
  mounted() {
    eventBus.on("linkAddCateOptions", (val) => {
      this.options = val;
      console.log(val)
    });
    eventBus.on("addLink", (val) => {
      if (val === "OK") {
        this.formState = {
          title: "",
          cateTitle: "",
          cate_id: "",
          url: "",
          icon: "",
          description: "",
        };
      }
    });
    eventBus.on('pageQueryData',(val)=>{
      this.formState.title = val.title;
      this.formState.url = val.url;
      this.checkHttp();
    });
  },
  watch: {
    formState: {
      handler(nval) {
        eventBus.emit("addLink", nval);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleChange(value) {
      this.formState.cate_id = value;
    },
    checkHttp() {
      const that = this;
      let url = that.formState.url !== undefined ? that.formState.url : "";
      if (url.indexOf(".") < 0) {
        message.error("请输入正确的网址");
        return false;
      }

      //判断里边是否包含/
      if (url.indexOf("/") < 0) {
        url = url + "/";
      }

      if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
        that.formState.url = "http://" + url;
      }
      //字符串截取
      var patt = /(http|https):\/\/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/i
      let host = that.formState.url.match(patt);
      tool
        .checkImgExists(host[0] + "/favicon.ico")
        .then(() => {
          that.formState.icon = host[0] + "/favicon.ico";
        })
        .catch(() => {
          that.formState.icon = "";
        });
    },
  },
};
</script>

<style scoped></style>
