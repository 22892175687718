<template>
  <a-layout>
    <a-layout-sider class="p-sider" width="280px;overflow: auto; height: 100vh; position: fixed; left: 0px;">
      <div class="logo">
        <img src="https://oss.pigwan.net/link/images/logo_light.png" alt="">
      </div>
      <a-divider style="height: 1px; background-color: #313437"/>
      <menu-layout></menu-layout>

      <div class="about">
        <a href="https://mp.weixin.qq.com/s/tqtnJSvIJAFioyrhy-coXQ">
          <icon-font type="link-like"/>
          <span class="about-site">关于本站</span>
        </a>
        <div class="qr">
          <img src="https://oss.pigwan.net/link/images/qr.jpg" alt="">
        </div>
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <header-layout></header-layout>
      </a-layout-header>
      <a-layout-content style="height: calc(100vh - 64px)">
        <router-view></router-view>
        <a-layout-footer style=" position: fixed;bottom: 0px;padding-left: 10px;">
          <footer-layout></footer-layout>
        </a-layout-footer>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>

import MenuLayout from "./MenuLayout";
import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";
import IconFont from "../utils/IconFont";

import {
  Divider,
  Layout,
  LayoutHeader,
  LayoutSider,
  LayoutContent,
  LayoutFooter,
} from "ant-design-vue/es";

export default {
  name: "BaseLayout",
  components: {
    IconFont,
    MenuLayout,
    FooterLayout,
    HeaderLayout,
    ADivider: Divider,
    ALayout: Layout,
    ALayoutHeader: LayoutHeader,
    ALayoutSider: LayoutSider,
    ALayoutContent: LayoutContent,
    ALayoutFooter:LayoutFooter,
  },
};
</script>

<style scoped>
.logo {
  width: 190px;
  height: 60px;
  margin: 20px 45px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-sider {
  display: table-cell;
  background: #2c2e2f;
  z-index: 555;
  overflow: auto;
  height: 100vh;
  left: 0;
}

.about {
  width: 200px;
  margin: 50px 40px 0;
}

.about a {
  color: #979898;
  text-decoration: none;
  display: block;
  padding: 13px 5px;
  border-bottom: 1px solid #313437;
}

.about-site {
  margin: 0 20px;
}

.qr {
  margin-top: 20px;
  width: 120px;
  height: 120px;
}

.qr img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>
