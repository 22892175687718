<template>
  <div>
    <a-menu
      v-model:selectedKeys="selectedKeys"
      style="width: 200px; margin: 0 40px"
      mode="inline"
    >
      <div v-if="menus.length === 0">
        <div class="add-cate" @click="addCate()">添加分组</div>
      </div>
      <div v-else>
        <template v-for="menu in menus">
          <a-sub-menu
            v-if="menu.children && menu.children.length > 0"
            :key="menu.id"
          >
            <template #icon>
              <icon-font :type="menu.icon" />
            </template>
            <template #title>{{ menu.title }}</template>
            <a-menu-item v-for="subMenu in menu.children" :key="subMenu.id">
              <a :href="'#' + subMenu.title">{{ subMenu.title }}</a>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item v-else :key="menu.id">
            <template #icon>
              <icon-font :type="menu.icon" />
            </template>
            <a :href="'#' + menu.title">{{ menu.title }}</a>
          </a-menu-item>
        </template>
        <div v-show="editSwitch" class="add-cate" @click="addCate()">添加分组</div>
      </div>
    </a-menu>
    <a-modal v-model:visible="addCateVisible" title="添加分组" width="960px">
      <template #footer>
        <a-button key="submit" type="primary" @click="submitCate()"
          >提交
        </a-button>
      </template>
      <add-cate></add-cate>
    </a-modal>
  </div>
</template>

<script>
import {
  Menu,
  MenuItem,
  Modal,
  Button,
  SubMenu,
  message,
} from "ant-design-vue/es";
import IconFont from "../utils/IconFont";
import store from "../store";
import eventBus from "vue3-eventbus";
import AddCate from "../components/AddCate";
import cate from "../api/cate";
export default {
  name: "MenuLayout",
  components: {
    AddCate,
    IconFont,
    AMenu: Menu,
    AModal: Modal,
    AButton: Button,
    ASubMenu: SubMenu,
    AMenuItem: MenuItem,
  },
  data() {
    return {
      addCateVisible: false,
      addData: {},
      menus: [],
      systemMenu: {},
      selectedKeys: [],
      editSwitch:false,
    };
  },

  created() {
    if (store.state.userInfo) {
      this.isLogin = true;
      this.userInfo = store.state.userInfo;
    }
  },
  mounted() {
    if (this.isLogin) {
      this.getCate();
    } else {
      this.getCatePublic();
    }
    eventBus.on("addCate", (val) => {
      this.addData = val;
    });
    eventBus.on("editSwitch", (val) => {
      this.editSwitch = val;
    });
  },
  methods: {
    getCatePublic() {
      const that = this;
      cate
        .listPublic()
        .then((res) => {
          if (res.code === 0) {
            that.menus = res.data;
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },
    getCate() {
      const that = this;
      cate
        .list()
        .then((res) => {
          if (res.code === 0) {
            that.menus = res.data;
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },
    getListAdd() {
      cate
        .listAdd()
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            let cateOptions = [
              {
                value: "0",
                label: "无上级分组",
              },
            ];
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                let item = { value: data[i].id, label: data[i].title };
                cateOptions.push(item);
              }
            }
            eventBus.emit("cateOptions", cateOptions);
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },
    addCate() {
      this.getListAdd();
      this.addCateVisible = true;
    },
    submitCate() {
      const that = this;
      if (that.addData.title === "" || that.addData.title.length > 6) {
        message.warning("分组名长度在1-6个字符之间");
        return false;
      }
      cate
        .addData(that.addData)
        .then((res) => {
          if (res.code === 0) {
            message.success(res.msg);
            this.getCate();
            this.addCateVisible = false;
            eventBus.emit("addCate", "OK");
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员！");
        });
    },
  },
};
</script>

<style scoped>
.add-cate {
  border: 1px dashed #979898;
  width: 80%;
  margin: 30px auto 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.ant-menu-item {
  height: 50px !important;
}
</style>
