<template>
  <a-form id="registerLogin" :model="formState" @finish="fromFinish">
    <a-form-item name="username">
      <a-input
        v-model:value="formState.username"
        size="large"
        placeholder="请输入用户名（6-20位字母、数字、下划线）"
      >
        <template #prefix>
          <user-outlined
            style="color: #1890ff; margin-right: 4px"
            type="user"
          />
        </template>
      </a-input>
    </a-form-item>

    <a-form-item name="password">
      <a-input-password
        v-model:value="formState.password"
        size="large"
        placeholder="请输入密码，至少6位"
      >
        <template #prefix>
          <lock-outlined
            style="color: #1890ff; margin-right: 4px"
            type="password"
          />
        </template>
      </a-input-password>
    </a-form-item>

    <a-form-item name="repeat_password">
      <a-input-password
        v-model:value="formState.repeat_password"
        size="large"
        placeholder="确认密码"
      >
        <template #prefix>
          <lock-outlined
            style="color: #1890ff; margin-right: 4px"
            type="repeat_password"
          />
        </template>
      </a-input-password>
    </a-form-item>

    <a-form-item name="phone" id="phone">
      <a-input
        v-model:value="formState.phone"
        size="large"
        placeholder="请输入手机号"
      >
        <template #prefix>
          <mobile-outlined
            style="color: #1890ff; margin-right: 4px"
            type="mobile"
          />
        </template>
      </a-input>
    </a-form-item>

    <a-row>
      <a-col :span="16">
        <a-form-item name="phone_code">
          <a-input
            v-model:value="formState.phone_code"
            size="large"
            placeholder="请输入验证码"
          >
            <template #prefix>
              <mail-outlined
                style="color: #1890ff; margin-right: 4px"
                type="user"
              />
            </template>
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-button
          size="large"
          style="font-size: 14px; float: right; width: 112px"
          @click="getPhoneCode"
          :disabled="disableSmsBtn"
          >{{ tips }}
        </a-button>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="12">
        <a-button
          size="large"
          type="primary"
          style="width: 100%; font-size: 14px"
          html-type="submit"
          :disabled="disableRegisterBtn"
          :loading="loadingRegisterBtn"
          >注册
        </a-button>
      </a-col>
      <a-col :span="12">
        <router-link to="/login">
          <a-button
            size="large"
            type="link"
            style="width: 100%; font-size: 14px; text-align: right"
            @click="login"
            >使用已有账号登陆
          </a-button>
        </router-link>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  InputPassword,
  Row,
  Col,
  Button,
  message,
} from "ant-design-vue/es";
import {
  UserOutlined,
  LockOutlined,
  MobileOutlined,
  MailOutlined,
} from "@ant-design/icons-vue";
import login from "../../api/login";
import tool from "../../utils/tool";
import store from "../../store";
import router from "../../router";

export default {
  name: "Register",
  data() {
    return {
      formState: {
        username: "",
        password: "",
        repeat_password: "",
        phone: "",
        phone_code: "",
      },
      disableSmsBtn: false,
      disableRegisterBtn: false,
      loadingRegisterBtn: false,
      tips: "获取验证码",
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    MailOutlined,
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    AInputPassword: InputPassword,
    ARow: Row,
    ACol: Col,
    AButton: Button,
  },
  created() {},
  methods: {
    login() {
      router.push({
        path: "/login",
        query: this.$route.query,
      });
    },
    //获取验证码
    getPhoneCode() {
      let that = this;
      if (that.disableSmsBtn) {
        return false;
      }
      that.disableSmsBtn = true;

      if (!tool.isPhoneNumber(that.formState.phone)) {
        //检查不通过
        message.warning("请输入正确的手机号码");
        return false;
      }
      login
        .sendCode("register", that.formState.phone)
        .then((res) => {
          if (res.code > 0) {
            message.warning(res.msg);
            that.disableSmsBtn = false;
          } else {
            message.success(res.msg);
            that.disableSmsBtn = true;
            let second = 60;
            const timer = setInterval(() => {
              second--;
              if (second > 0) {
                that.tips = second + "秒后获取";
              } else {
                that.disableSmsBtn = false;
                that.tips = "获取验证码";
                clearInterval(timer);
              }
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员！");
          that.disableSmsBtn = false;
        });
    },
    fromFinish(field) {
      //判断用户名长度，只能英文
      if (!tool.isValid(field.username)) {
        message.warning("用户名只能使用数字、字母、下划线");
        return false;
      }

      if (!tool.isLetter(field.username)) {
        message.warning("用户名必须包含字母");
        return false;
      }

      if (field.username.length < 6 || field.username.length > 20) {
        message.warning("用户名长度为6-20个字符");
        return false;
      }

      //判断密码长度
      if (field.password.length < 6 || field.password.length > 20) {
        message.warning("密码名长度为6-20个字符");
        return false;
      }

      //判断两次密码是否一致
      if (field.password !== field.repeat_password) {
        message.warning("两次密码输入不一致");
        return false;
      }
      //判断手机号
      if (!tool.isPhoneNumber(field.phone)) {
        //检查不通过
        message.warning("请输入正确的手机号码");
        return false;
      }
      //判断验证码
      if (field.phone_code.length !== 6) {
        message.warning("请输入正确的验证码");
        return false;
      }

      let that = this;
      if (that.disableRegisterBtn) {
        return false;
      }

      that.disableRegisterBtn = true;
      that.loadingRegisterBtn = true;

      login
        .register(field)
        .then((res) => {
          if (res.code > 0) {
            message.warning(res.msg);
            that.disableRegisterBtn = false;
            that.loadingRegisterBtn = false;
          } else {
            message.success(res.msg);
            store.commit("login", res.data.user);
            let url = this.$route.query.redirect;
            if (this.$route.query.redirect === undefined) {
              url = "/";
            }
            router.push({
              path: url,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员！");
          that.disableRegisterBtn = false;
          that.loadingRegisterBtn = false;
        });
    },
  },
};
</script>

<style scoped></style>
