import tool from "../utils/tool";

export default {
  userInfo: tool.isJsonString(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo"))
    : "",
  authorization: localStorage.getItem("authorization")
    ? localStorage.getItem("authorization")
    : "",
  config: {
    ad: localStorage.getItem("ad") !== "false",
  },
};
