<template>
  <a-form id="fromLogin" :model="formState" @finish="fromFinish">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="账号密码登陆">
        <a-form-item name="username">
          <a-input
            v-model:value="formState.username"
            size="large"
            placeholder="请输入用户名/手机号"
          >
            <template #prefix>
              <user-outlined
                style="color: #1890ff; margin-right: 4px"
                type="user"
              />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="password">
          <a-input-password
            v-model:value="formState.password"
            size="large"
            placeholder="请输入密码"
          >
            <template #prefix>
              <lock-outlined
                style="color: #1890ff; margin-right: 4px"
                type="password"
              />
            </template>
          </a-input-password>
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="2" tab="手机号登录" force-render>
        <a-form-item name="phone">
          <a-input
            v-model:value="formState.phone"
            size="large"
            placeholder="请输入手机号"
          >
            <template #prefix>
              <mobile-outlined
                style="color: #1890ff; margin-right: 4px"
                type="mobile"
              />
            </template>
          </a-input>
        </a-form-item>

        <a-row>
          <a-col :span="16">
            <a-form-item name="phone_code">
              <a-input
                v-model:value="formState.phone_code"
                size="large"
                placeholder="请输入验证码"
              >
                <template #prefix>
                  <mail-outlined
                    style="color: #1890ff; margin-right: 4px"
                    type="user"
                  />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-button
              size="large"
              style="font-size: 14px; float: right; width: 112px"
              @click="getPhoneCode"
              :disabled="disableSmsBtn"
            >{{ tips }}
            </a-button>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
    <a-form-item>
      <a-button type="primary" size="large" html-type="submit" block :disabled="disableLoginBtn"
                :loading="loadingRegisterBtn">登陆
      </a-button>
    </a-form-item>
  </a-form>
  <div class="user-login-other">
    <router-link to="#">忘记密码</router-link>
    <a class="register" @click="register">注册账户</a>
  </div>
</template>
<script>
import {
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  InputPassword,
  Row,
  Col,
  Button,
  message
} from "ant-design-vue/es";
import {
  UserOutlined,
  LockOutlined,
  MobileOutlined,
  MailOutlined
} from "@ant-design/icons-vue";

import router from "../../router";
import tool from "../../utils/tool";
import login from "../../api/login";
import store from "../../store";

export default {
  name: "Login",
  data() {
    return {
      activeKey: "1",
      formState: {
        username: "",
        password: "",
        phone: "",
        phone_code: ""
      },
      disableSmsBtn: false,
      disableLoginBtn: false,
      loadingRegisterBtn: false,
      tips: "获取验证码"
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    MailOutlined,
    ATabs: Tabs,
    ATabPane: TabPane,
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    AInputPassword: InputPassword,
    ARow: Row,
    ACol: Col,
    AButton: Button
  },
  created() {
  },
  methods: {
    register() {
      router.push({
        path: "/register",
        query: this.$route.query
      });
    },
    //获取验证码
    getPhoneCode() {
      let that = this;
      if (that.disableSmsBtn) {
        return false;
      }

      that.disableSmsBtn = true;

      if (!tool.isPhoneNumber(that.formState.phone)) {
        //检查不通过
        message.warning("请输入正确的手机号码");
        that.disableSmsBtn = false;
        return false;
      }
      login
        .sendCode("login", that.formState.phone)
        .then((res) => {

          if (res.code > 0) {
            message.warning(res.msg);
            that.disableSmsBtn = false;
          } else {
            message.success(res.msg);
            that.disableSmsBtn = true;
            let second = 60;
            const timer = setInterval(() => {
              second--;
              if (second > 0) {
                that.tips = second + "秒后获取";
              } else {
                that.disableSmsBtn = false;
                that.tips = "获取验证码";
                clearInterval(timer);
              }
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员！");
          that.disableSmsBtn = false;
        });
    },

    fromFinish(field) {

      let that = this;

      //开始检查提交数据
      if (that.activeKey === "1") {
        //判断用户名，只能数字、字母、下划线
        if (!tool.isValid(field.username)) {
          message.warning("用户名只能使用数字、字母、下划线");
          return false;
        }

        if (field.username.length < 6 || field.username.length > 20) {
          message.warning("用户名长度为6-20个字符");
          return false;
        }

        //判断密码长度
        if (field.password.length < 6 || field.password.length > 20) {
          message.warning("密码名长度为6-20个字符");
          return false;
        }

      } else if (that.activeKey === "2") {
        //判断手机号
        if (!tool.isPhoneNumber(field.phone)) {
          //检查不通过
          message.warning("请输入正确的手机号码");
          return false;
        }
        //判断验证码
        if (field.phone_code.length !== 6) {
          message.warning("请输入正确的验证码");
          return false;
        }
      }

      field.type = that.activeKey;

      if (that.disableLoginBtn) {
        return false;
      }

      that.disableLoginBtn = true;
      that.loadingRegisterBtn = true;
      message.info("登陆中...");
      login
        .login(field)
        .then((res) => {
          if (res.code > 0) {
            message.warning(res.msg);
            that.disableLoginBtn = false;
            that.loadingRegisterBtn = false;
          } else {
            message.success(res.msg);
            console.log(res);
            store.commit("login", res.data.user);
            let url = this.$route.query.redirect;
            if (this.$route.query.redirect === undefined) {
              url = "/";
            }
            router.push({
              path: url
            });
          }
        })
        .catch((e) => {
          let error = JSON.parse(JSON.stringify(e));
          message.error(
            "出现错误，请联系管理员！错误代码：" +
            error.status +
            ",错误信息：" +
            error.message
          );
          that.disableLoginBtn = false;
          that.loadingRegisterBtn = false;
        });
    }
  }
};
</script>

<style scoped>
.user-login-other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}

.register {
  float: right;
}
</style>
