<template>
  <div class="top">
    <div class="menu">
      <a v-show="isLogin" href="/help" target="_blank">
        <icon-font type="link-info-circle" />
        <span class="title">使用说明</span>
      </a>

      <div
        v-show="editSwitch"
        @click="addLink()"
        style="display: inline-block; margin: 0 5px; cursor: pointer"
      >
        <icon-font type="link-shoucang" />
        <span class="title" style="color: #fb5058">添加</span>
      </div>

    </div>
    <div class="user-info">
      <div v-if="isLogin">
        <a-dropdown placement="bottomCenter">
          <a class="ant-dropdown-link" @click.prevent>
            <div class="p-user-info">
              <a-avatar :src="userInfo.avatar" />
              <span class="p-username">{{ userInfo.username }}</span>
            </div>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="logout">
                <icon-font type="link-logout" />
                <span>退出</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div v-else>
        <router-link to="/login" style="float: right">登陆</router-link>
      </div>
    </div>

    <div v-show="isLogin" style="display: inline-block;float: right;margin-right: 30px;font-size:13px ">
      <div style="vertical-align: middle;display: inline-block;margin-right: 5px;color: #555555">编辑模式</div><a-switch style="vertical-align: middle;display: inline-block"  size="small" v-model:checked="editSwitch" @change="changeEditSwitch()"/>
    </div>
  </div>

  <a-modal v-model:visible="addLinkVisible" title="添加链接" width="960px">
    <template #footer>
      <a-button key="submit" type="primary" @click="submitLink()"
        >提交
      </a-button>
    </template>
    <add-link></add-link>
  </a-modal>
</template>

<script>
import {
  message,
  Menu,
  Avatar,
  Modal,
  Button,
  MenuItem,
  Dropdown,
    Switch,
} from "ant-design-vue/es";
import IconFont from "../utils/IconFont";
import store from "../store";
import eventBus from "vue3-eventbus";
import AddLink from "../components/AddLink";
import cate from "../api/cate";
import link from "../api/Link";

export default {
  name: "HeaderLayout",
  components: {
    AddLink,
    IconFont,
    AMenu: Menu,
    AModal: Modal,
    ASwitch:Switch,
    AAvatar: Avatar,
    AButton: Button,
    ADropdown: Dropdown,
    AMenuItem: MenuItem,
  },
  data() {
    return {
      editSwitch:false,
      isLogin: false,
      userInfo: {},
      addLinkVisible: false,
      addData: {},
    };
  },
  created() {
    if (store.state.userInfo) {
      this.isLogin = true;
      this.userInfo = store.state.userInfo;
    }
  },
  mounted() {
    eventBus.on("addLink", (val) => {
      this.addData = val;
    });
  },
  methods: {
    logout() {
      store.commit("login", "");
      store.commit("changeAuthorization", "");
      location.reload();
    },
    addLink() {
      this.getCateListAdd();
      this.addLinkVisible = true;
    },
    getCateListAdd() {
      cate
        .list()
        .then((res) => {
          if (res.code === 0) {
            let linkAddCateOptions = [];
            if (res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].children && res.data[i].children.length > 0) {
                  let son = [];
                  for (let j = 0; j < res.data[i].children.length; j++) {
                    son.push({
                      value: res.data[i].children[j].id,
                      label: res.data[i].children[j].title,
                    });
                  }
                  let item = { label: res.data[i].title, options: son };

                  linkAddCateOptions.push(item);
                } else {
                  let item = {
                    value: res.data[i].id,
                    label: res.data[i].title,
                  };
                  linkAddCateOptions.push(item);
                }
              }
            }
            eventBus.emit("linkAddCateOptions", linkAddCateOptions);
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员");
        });
    },

    submitLink() {
      const that = this;
      if (that.addData.title === "" || that.addData.title.length > 30) {
        message.warning("网站标题长度在1-30个字符之间");
        return false;
      }

      if (that.addData.url === "" || that.addData.url.length > 250) {
        message.error("网址字符长度在1-250个字符之间");
        return false;
      } else if (that.addData.url.indexOf(".") < 0) {
        message.error("请输入正确的网址");
        return false;
      }

      if (that.addData.cate_id === "" || that.addData.cate_id === 0) {
        message.error("请选择分组");
        return false;
      }
      if (
        that.addData.url.indexOf("http://") < 0 &&
        that.addData.url.indexOf("https://") < 0
      ) {
        that.addData.url = "http://" + that.addData.url;
      }

      if (that.addData.icon.indexOf("http") < 0) {
        that.addData.icon = that.addData.title.substring(0, 1);
      }

      link
        .addData(that.addData)
        .then((res) => {
          if (res.code === 0) {
            message.success(res.msg);
            this.addLinkVisible = false;
            eventBus.emit("addLink", "OK");
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.msg
            );
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("出现错误，请联系管理员！");
        });
    },
    changeEditSwitch(){
      eventBus.emit("editSwitch", this.editSwitch);
    }
  },

};
</script>

<style scoped>
.menu a {
  margin: 0 10px;
}

a,
a:hover {
  color: #000000;
}

.top {
  padding: 0 20px;
  font-size: 16px;
}

.top .title {
  margin-left: 5px;
}

.menu,
.user-info {
  display: inline-block;
  vertical-align: middle;
}

.user-info {
  float: right;
  margin-right: 30px;
}

.ant-dropdown-link {
  float: right;
}

.p-user-info {
  float: right;
}

.p-username {
  margin-left: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
</style>
