<template>
  <div class="p-footer">
    <div class="verse">

    </div>
    <div class="p-footer-copyright">
      <div class="copyright">
        Copyright © 2022
        <a class="p-link" target="_blank" href="https://pigwan.me">🎉<span style="padding-left: 10px">version 1.0</span></a>
        <a class="p-link" target="_blank" href="https://pigwan.me">🛠️<span style="padding-left: 10px">Powered by Pigwan</span></a>
        <a class="p-link" target="_blank" href="https://www.miit.gov.cn/">👮<span style="padding-left: 10px">豫ICP备16017200号-3</span></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLayout",
  components: {},
};
</script>

<style scoped>


.p-link {
  margin-right: 5px;
}

.p-link,
.p-footer-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.p-link,
.p-version,
.p-create-time {
  display: inline-block;
  margin-left: 10px;
}
</style>
