<template>
  <a-form
    ref="formRef"
    :model="formState"
    :label-col="{ style: { width: '200px' } }"
  >
    <a-form-item has-feedback label="分组名称" name="title">
      <a-input
        v-model:value="formState.title"
        type="text"
        autocomplete="off"
        style="width: 400px"
        placeholder="请输入分组名称，如：设计资料"
      />
    </a-form-item>

    <a-form-item has-feedback label="上级分组" name="pid">
      <a-select
        v-model:value="formState.pidTitle"
        style="width: 400px"
        :options="options"
        @change="handleChange"
      ></a-select>
    </a-form-item>
  </a-form>
</template>


<script>
import { Form, FormItem, Input, Select } from "ant-design-vue/es";
import eventBus from "vue3-eventbus";

export default {
  name: "AddCate",
  components: {
    AForm: Form,
    ASelect: Select,
    AInput: Input,
    AFormItem: FormItem,
  },
  data() {
    return {
      formState: {
        title: "",
        pidTitle: "无上级分组",
        pid: 0,
        icon: "",
      },
      options: [],
    };
  },
  mounted() {
    eventBus.on("cateOptions", (val) => {
      console.log(val);
      this.options = val;
    });
    eventBus.on("addCate", (val) => {
      if (val === "OK") {
        this.formState = {
          title: "",
          pidTitle: "无上级分组",
          pid: 0,
          icon: "",
        };
      }
    });
  },
  watch: {
    formState: {
      handler(nval) {
        eventBus.emit("addCate", nval);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleChange(value) {
      this.formState.pid = value;
    },
  },
};
</script>

<style scoped></style>
