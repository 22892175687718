export default {
  login(state, data) {
    state.userInfo = data;
    localStorage.setItem("userInfo", JSON.stringify(data));
  },
  changeAuthorization(state, authorization) {
    state.authorization = authorization;
    localStorage.setItem("authorization", authorization);
  },
  changeAd(state, status) {
    state.config.ad = status;
    localStorage.setItem("ad", status);
  },
};
